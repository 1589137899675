<template>
  <el-dialog
    class="add-course"
    :title="`添加${addCourseTitle}课程`"
    :visible.sync="addCourseDialog"
    :before-close="cancel"
    :close-on-click-modal="false"
  >
    <el-form :model="addCourseForm" inline>
      <el-form-item label="课程名称">
        <el-input
          style="width: 286px"
          clearable=""
          placeholder="请输入课程名称"
          v-model="addCourseForm.courseName"
          @change.native="searchCourse"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchCourse()">查询</el-button>
      </el-form-item>
      <div class="table-box">
        <el-table
          :data="courseTableData"
          ref="multipleTable"
          @selection-change="_handleSelectionChange"
          height="calc(100vh - 550px)"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="课程名称" prop="courseName" />
          <el-table-column label="课程类型" prop="courseType">
            <template slot-scope="{row}">{{row.courseType||'—'}}</template>
          </el-table-column>
        </el-table>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 56px;
          "
        >
          <el-pagination
            @current-change="_handleCurrentChange"
            :current-page.sync="addCourseForm.page"
            :page-size="addCourseForm.pageSize"
            layout="total, prev, pager, next"
            :total="addCourseForm.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$parent.addCourseDialog = false">取 消</el-button>
      <el-button type="primary" @click="addLineCourse">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { courseFindByPageListApi } from '@/api/course.js'
import { createOrUpdateApi } from '@/api/study.js'
export default {
  props: {
    addCourseTitle: {
      type: String,
      default: ''
    },
    courseType: {
      type: Number,
      default: 0
    },
    addCourseDialog: {
      type: Boolean,
      default: false
    },
    currentItem: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      addCourseForm: {
        courseName: '',
        total: 0,
        page: 1,
        pageSize: 10
      },

      courseTableData: [] // 线上&线下课程选择
    }
  },
  created () {
    this.getlineCourseList()
  },
  watch: {
    'addCourseForm.courseName' (value) {
      if (!value) {
        this.addCourseForm.page = 1
        this.getlineCourseList()
      }
    }
  },
  methods: {
    cancel () {
      this.$parent.addCourseDialog = false
    },
    // 获取线上&线下的列表
    getlineCourseList () {
      courseFindByPageListApi({
        ...this.addCourseForm,
        type: this.courseType
      }).then((res) => {
        if (res.code === 200) {
          this.courseTableData = res.object.results
          this.addCourseForm.total = res.object.total
        }
      })
    },
    searchCourse (value) {
      this.addCourseForm.page = 1
      this.getlineCourseList()
    },
    // 表格选中
    _handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 添加线上&&线下课程
    addLineCourse () {
      if (!this.multipleSelection.length) return this.$message.warning('请选择课程')
      const studyPlanCourseList = []
      this.multipleSelection.forEach((item, index) => {
        const obj = {
          ...item,
          id: null,
          studyPlanId: this.detailId,
          relevanceId: item.id,
          sort: index + 1,
          name: item.courseName,
          fileDuration: item.fileDuration,
          learnDuration: item.learnDuration
        }
        studyPlanCourseList.push(obj)
      })

      this.addCourseApi({
        stageId: this.currentItem.stageId,
        studyPlanId: this.$route.query.id,
        type: this.courseType,
        studyPlanCourseList
      })
    },
    // 添加阶段安排接口调用
    addCourseApi (params) {
      createOrUpdateApi(params).then((res) => {
        if (res.code === 200) {
          this.$parent.addCourseDialog = false
          this.$parent.getClassList()
          this.$message.success('操作成功！')
        }
      })
    },
    _handleSizeChange (val) {
      this.addCourseForm.pageSize = val
      this.getlineCourseList()
    },
    _handleCurrentChange (val) {
      this.addCourseForm.page = val
      this.getlineCourseList()
    }
  }
}
</script>

<style lang="scss" scoped>
    .table-box{
      margin-top: 24px;
      border: 1px solid #F3F3F3;
      /deep/th{
        font-size: 14px;
        color: rgba(0, 0, 0, 0.90);
      }
      /deep/td{
        font-size: 14px;
        color: rgba(0, 0, 0, 0.90);
      }
    }
</style>
